// exports array of my roles
export const myRoles = [
  "Mobile App Developer",
  "Web Developer",
  "IOT Developer",
  "Data Scientist",
];

export const allBlogs = [
  {
    id: 1,
    title: "Opticon: Drowsiness Mitigation using IOT and AI",
    image: "images/blog/opticon-lp.jpg",
    filesource: "../../blogs/opticon.md",
    date: "09 February, 2020",
    author: "Maulana Arif",
    category: "Projects",
  },
  {
    id: 2,
    title: "Greeny: Smart Mobile Apps for Urban Agriculture",
    image: "images/blog/greeny-lp.jpg",
    filesource: "../../blogs/greeny.md",
    date: "07 February, 2020",
    author: "Bolby",
    category: "Projects",
  },
  {
    id: 3,
    title: "Pita ID: Text Extraction from KTP using OCR",
    image: "images/blog/pitaID-lp.png",
    filesource: "../../blogs/pitaID.md",
    date: "06 February, 2020",
    author: "Bolby",
    category: "Business",
  },
  {
    id: 4,
    title: "Simak Gizi: Measuring Nutrition using Various Methods",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Bolby",
    category: "Reviews",
  },
  {
    id: 5,
    title: "Saling Bantu: Match Maker for UMKM and Investor",
    image: "images/blog/2.svg",
    filesource: "../../blogs/common-misconceptions-about-payment.md",
    date: "07 February, 2020",
    author: "Bolby",
    category: "Tutorial",
  },
  {
    id: 6,
    title: "Amazing E Grocery: Web Based Grocery Shopping",
    image: "images/blog/3.svg",
    filesource: "../../blogs/things-to-know-about-startup-business.md",
    date: "06 February, 2020",
    author: "Bolby",
    category: "Business",
  },
  {
    id: 7,
    title: "Meteranku: Web Based Water Usage Monitoring",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Bolby",
    category: "Reviews",
  },
  {
    id: 8,
    title: "Coin Batam: Cryptocurrency Trend Monitoring",
    image: "images/blog/2.svg",
    filesource: "../../blogs/common-misconceptions-about-payment.md",
    date: "07 February, 2020",
    author: "Bolby",
    category: "Tutorial",
  },
  {
    id: 9,
    title: "Rumpi App: Web Based Chatting App",
    image: "images/blog/3.svg",
    filesource: "../../blogs/things-to-know-about-startup-business.md",
    date: "06 February, 2020",
    author: "Bolby",
    category: "Business",
  },
]; 